import styled from 'styled-components'
import { socials } from 'config/constants/exchange'
import PageSection from 'components/PageSection'
import useTheme from 'hooks/useTheme'
import { Button, Flex, Heading, Link, Text, useMatchBreakpoints } from '@pancakeswap/uikit'
import { NextLinkFromReactRouter } from 'components/NextLink'
import { PageMeta } from 'components/Layout/Page'
import Hero from './components/Hero'
import MetricsSection from './components/MetricsSection'





const StyledHeroSection = styled(PageSection)`
  margin-top: 16px;
  
  ${({ theme }) => theme.mediaQueries.md} {
    margin-top: 20px;
  }
`


const Home: React.FC = () => {
  const { isDark } = useTheme()
  const { isMobile } = useMatchBreakpoints();
  return (
    <>
      <PageMeta />

      <StyledHeroSection
        innerProps={{ style: { margin: '0', width: '100%' } }}
        // background={
        //   theme.isDark
        //     ? 'radial-gradient(103.12% 50% at 50% 50%, #262626 0%, #171717 100%)'
        //     : 'linear-gradient(139.73deg, #262626 0%, #fefefe 100%)'
        // }
        index={2}
        hasCurvedDivider={false}
        marginBottom="10px"
      >
        <Hero />
      </StyledHeroSection>
      <PageSection
        innerProps={{ style: { margin: '0', width: '100%' } }}
        // background={
        //   theme.isDark
        //     ? 'linear-gradient(180deg, #090909 22%, #202020 100%)'
        //     : 'linear-gradient(180deg, #FFFFFF 22%, #D7CAEC 100%)'
        // }
        index={2}
        hasCurvedDivider={false}
      >
        <MetricsSection />
      </PageSection>


      <PageSection
        innerProps={{ style: { margin: '0', width: '100%' } }}
        // background={
        //   theme.isDark
        //     ? 'linear-gradient(180deg, #090909 22%, #202020 100%)'
        //     : 'linear-gradient(180deg, #FFFFFF 22%, #D7CAEC 100%)'
        // }
        index={2}
        hasCurvedDivider={false}
      >
        <Flex mt="48px" padding="40px" borderRadius="20px" justifyContent="center" alignItems="center" flexDirection="column" background={isDark ? `#13132c` : "#fefefe"}>
          <Heading mb="10px" color='primary' textAlign="center" scale="xl">
            Get Reward USDT All Trade
          </Heading>
          {isMobile ? (
            <Text color='textSubtle' mb="10px" textAlign="center">
              Earn from using the HENGEx ecosystem. Every transaction will be processed by system. Receive reward Automatically to wallet
            </Text>
          ) : (
            <Text color='textSubtle' mb="10px" textAlign="center">
              Earn from using the HENGEx ecosystem. Every transaction will be <br /> processed by system. Receive reward Automatically to wallet
            </Text>
          )}

          <NextLinkFromReactRouter to="/swap" >
            <Button variant='primary' mt="10px">Buy HENGCoin</Button>
          </NextLinkFromReactRouter>
        </Flex>
        <Flex justifyContent="center" mt="48px" >
          {socials.map((social, index) => {
            const iconProps = {
              width: "28px",
              color: isDark ? "#fff" : "#000000",
              style: { cursor: "pointer" },
            };
            const Icon = social.icon;
            return (
              <Link external key={social.label} href={social.href} aria-label={social.label} mr="16px">
                <Icon {...iconProps} />
              </Link>
            );
          })}
        </Flex>
      </PageSection>

    </>
  )
}

export default Home